import React from 'react';
import { getNewsItem } from '../data/news';
import { useParams } from 'react-router-dom';
import { Scrollbar } from "react-scrollbars-custom";
import Moment from 'moment';
import{
	MDBRow,
  MDBCol,
  MDBCard
} from 'mdbreact';

function NewsDetailsPage(props) {
    //let params = useParams();
    //let newsItem = getNewsItem(parseInt(params.id));

    return (
      <div className="d-flex align-items-center justify-content-center">
        <MDBCard className="news-card">
				<Scrollbar
									id="scrollbar"
									style={{ width: "100%", height: "75vh"}}
									maximalThumbYSize = {100}
									noScrollX = {true}
				>
          <MDBRow className="m-0">
          	<span className="newsdetails-title font-weight-bold">{props.news.title}</span>
          </MDBRow>
          <MDBRow className="m-0">
          	<span className="newsdetails-content">{props.news.content}</span>
          </MDBRow>
          <MDBRow className="m-0">
          	<span className="newsdetails-date font-italic">{Moment(props.news.date).format('DD.MM.YY')}</span>
          </MDBRow>
				</Scrollbar>
        </MDBCard>
      </div>
    );
}
export default NewsDetailsPage;
