import React from 'react';
import { useOutletContext } from 'react-router-dom';
import SpagComponent from './SpagComponent';
import InterB2BAxios from '../../../Service/InterB2BAxios';
import moment from 'moment';
class SpagPart extends React.Component {

  constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this);
		this.state = {linestartx: -1,
                  linestarty: -1,
                  load: false
									};
	};

	componentDidMount() {
		this.onSpag();
    /*if (this.props.xkoord === "nexthour") {
      let self = this;
      this.interAxios.get('/v1/spagx', function(response)
      {
          console.log("spagx.x: " + response.data.x);
          self.setState({
            modelx: response.data.x
          });
      });
    }*/

	};

  componentDidUpdate(prevProps, prevState) {
		if (this.props.line !== prevProps.line ){
          this.setState({load: false});
					this.onSpag();
				}
	}

  onSpag = () => {
    if (this.props.date === undefined || this.props.line === undefined) {
      this.setState({load: false });
    } else {
      let self = this;
      this.interAxios.get('/v1/search/spag', {params: {date: this.props.date, line: this.props.line}},  function(response)
      {
          self.setState({picture: response.data.picture,
                        linestartx: self.props.xCoord !== self.props.originalX ? self.props.xCoord : response.data.x,
                        linestarty: self.props.yCoord !== self.props.originalY ? self.props.yCoord : response.data.y,
                        originalLinestartx: response.data.x,
                        originalLinestarty: response.data.y,
                        hours: response.data.hours,
                        piers: response.data.piers,
                        load: true});
      });
    }
	}

  render() {
    //console.log(this.props.xCoord + ", " + this.props.originalX + ", " + this.props.yCoord + ", " + this.props.originalY);
    //window.innerWidth =   window.innerWidth > 500 ? 500 : window.innerWidth;
    let heightSize = window.innerWidth < 325 ? window.innerHeight - 170 : window.innerHeight -170;
    return (
      <>
      <div className="spag-bild justify-content-left" style={{height: heightSize + "px"}}>
        {this.state.load ?
        <SpagComponent linestartx={this.state.linestartx}
                       linestarty={this.state.linestarty}
                       originalLinestartx={this.state.originalLinestartx}
                       originalLinestarty={this.state.originalLinestarty}
                       maxmodelx={this.state.hours["Fin"]}
                       maxmodely={this.state.piers["Fin"]}
                       closestPier={this.props.closestPier}
                       mode={this.props.xkoord === "nexthour" ? "nexthour" : "linestart"}
                       image={this.state.picture}
                       hours={this.state.hours}
                       piers={this.state.piers}
                       selected={this.props.line}
                       searchIcon={false}
                       onSetXCoord={this.props.onSetXCoord}
                       onSetYCoord={this.props.onSetYCoord}/>: false}
      </div>
      </>
    );
  }
}

export default function SpagPartFunc(props) {
  const context = useOutletContext();
  return <SpagPart line={context[0]}
                   date={context[1]}
                   xkoord={context[2]}
                   closestPier={props.closestPier}
                   {...this}
                   onSetXCoord={props.onSetXCoord}
                   onSetYCoord={props.onSetYCoord}
                   xCoord={props.xCoord}
                   yCoord={props.yCoord}
                   originalX={props.originalX}
                   originalY={props.originalY}/>;
}
