import React from 'react';
import NewsList from '../components/news/NewsList';
import NewsModal from '../components/popup/NewsModal';
import NewsDetailsPage from './NewsDetailsPage';
import { Link } from 'react-router-dom';
import { useTitle } from './PageTemplate';
import uszogumi from "../assets/media/images/NaviPlan-Bouee2.png";
import{
	MDBSideNav,
	MDBRow
} from 'mdbreact';

import styles from '../assets/scss/modules/pagetemplate.module.scss';

class NewsPage extends React.Component {
  constructor(props) {
      super(props);
      this.state = {news: {}};
    };

  componentDidMount() {
    this.props.setTitle('Rubrique du capitaine');
  }

  onLink = (news) => {
		this.setState({news: news});
		this.props.handleSideNavToggle();
  }

  render() {
    return (
		<div className={styles.newspagebodywrapper + ' ' + styles.bodywrapperbackground}>
			<div style={{height: "555px"}}>
       <NewsList onLink = {this.onLink}/>

		     <div className="search-footer d-flex align-items-center justify-content-center">
		          <Link to="/search" onClick={() => this.props.onSetData(new Date(), "Tous", "Tous", "Tous", "Tous")}><img className="uszogumi-img" src={uszogumi}/>
							<div className="uszogumi-text-4">
							<MDBRow center className="py-0">
								<span className="">Choisir</span>
							</MDBRow>
							<MDBRow center className="py-0">
								<span className="">ma</span>
							</MDBRow>
							<MDBRow center className="py-0">
								<span className="">croisière</span>
							</MDBRow>
							</div>
							</Link>
		     </div>
			</div>
			<NewsModal news={this.state.news} nyit={this.props.nyit}/>
			</div>
    );
  }
}

export default NewsPage;
