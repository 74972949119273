import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import Moment from 'moment';
import{
	MDBRow,
  MDBCol
} from 'mdbreact';


class NewsItem extends React.Component {
  render() {
    return (
			<a className="p-0 m-0" style={{textTransform: "unset", width: "100%"}} onClick={() => this.props.onLink(this.props.data)}
			>
        <MDBRow className="p-0 m-0" style={{width: "100%", height: "90px"}}>
          <MDBCol size="3" className="pl-1 pr-1">
						<MDBRow className="m-0">
	            <span className="news-bold-text newsitem-date">{Moment(this.props.data.date).format('DD.MM.YY')}</span>
						</MDBRow>
          </MDBCol>
          <MDBCol size="9" className="text-left pl-1 pr-2">
            <MDBRow className="m-0">
              <span className="news-bold-text newsitem-title">{this.props.data.title}</span>
            </MDBRow>
            <MDBRow className="m-0 newsitem-content">
              <span className="news-text">{this.props.data.content}</span>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </a>
    );
  }
}

export default NewsItem;
