import React from 'react';
import { useOutletContext } from 'react-router-dom';
import InterB2BAxios from '../../../Service/InterB2BAxios';
import { Scrollbar } from "react-scrollbars-custom";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBScrollbar
} from 'mdbreact';

class MealPart extends React.Component {

  constructor(props) {
    super(props);
    this.interAxios = new InterB2BAxios(this);
    this.state = {mealtext: ""};
  };

  componentDidMount() {
    this.onRestaurant();

  }

  componentDidUpdate(prevProps, prevState) {
		if (this.props.line !== prevProps.line ){
          this.setState({mealtext: ""});
					this.onRestaurant();
				}
	}

  onRestaurant = () => {
    if (this.props.date === undefined || this.props.line === undefined) {
      this.setState({load: false });
    } else {
      let self = this;
      this.interAxios.get('/v1/search/restaurant', {params: {date: this.props.date, line: this.props.line}},  function(response)
      {
          self.setState({mealtext: response.data});

      });
    }
  }

  render() {

    return (
      <MDBScrollbar style={{maxHeight: "65vh"}}>
        <MDBRow className="m-0 padding-mealpart">
          <div dangerouslySetInnerHTML={{__html: this.state.mealtext}}/>
        </MDBRow>
      </MDBScrollbar>
    );
  }
}

export default function MealPartFunc() {
  const context = useOutletContext();
  return <MealPart line={context[0]} date={context[1]} {...this} />;
}
